// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
  getJSSThemePatterns,
} from '../JSSThemeDefault';
import { JSSThemeHeaderMenu61800 } from './JSSThemeHeaderMenu61800';
import { JSSThemeHeader61800 } from './JSSThemeHeader61800';
import { JSSThemeBetslip61800 } from './JSSThemeBetslip61800';
import { JSSThemeSportsMenu61800 } from './JSSThemeSportsMenu61800';
import { JSSThemeLayout61800 } from './JSSThemeLayout61800';
import { JSSThemeLanguageSwitcher61800 } from './JSSThemeLanguageSwitcher61800';
import { JSSThemeSearch61800 } from './JSSThemeSearch61800';
import { JSSThemeCoupons61800 } from './JSSThemeCoupons61800';
import { JSSThemeBannerItems61800 } from './JSSThemeBannerItems61800';
import { JSSThemeDefaultWidgets61800 } from './JSSThemeWidgets61800';
import { JSSThemeForm61800 } from './JSSThemeForm61800';
import { JSSThemeAuthForm61800 } from './JSSThemeAuthForm61800';
import { JSSThemeRegistration61800 } from './JSSThemeRegistration61800';
import { JSSThemeBalance61800 } from './JSSThemeBalance61800';
import { JSSThemeMyBets61800 } from './JSSThemeMyBets61800';
import { JSSThemeLive61800 } from './JSSThemeLive61800';
import { JSSThemeCasino61800 } from './JSSThemeCasino61800';
import { JSSThemeHeaderMobile61800 } from './JSSThemeHeaderMobile61800';
import { JSSThemeOutcomes61800 } from './JSSThemeOutcomes61800';
import { JSSThemeAccountSection61800 } from './JSSThemeAccountSection61800';
import { JSSThemePopularLeagues61800 } from './JSSThemePopularLeagues61800';
import { JSSThemeFooter61800 } from './JSSThemeFooter61800';
import { JSSThemeCookies61800 } from './JSSThemeCookies61800';
import { JSSThemeBonuses61800 } from './JSSThemeBonuses61800';
import { JSSThemeTooltip61800 } from './JSSThemeTooltip61800';
import { JSSThemeFooterAll61800 } from './JSSThemeFooterAll61800';
import { JSSThemePromotions61800 } from './JSSThemePromotions61800';
import { JSSThemeSitemap61800 } from './JSSThemeSitemap61800';
import { JSSThemeAffiliates61800 } from './JSSThemeAffiliates61800';
import { JSSThemeDropdownMenu61800 } from './JSSThemeDropdownMenu61800';
import { JSSThemeArticleBody61800 } from './JSSThemeArticleBody61800';
import { JSSThemeXtremepush61800 } from './JSSThemeXtremepush61800';
import { JSSThemeVirtuals61800 } from './JSSThemeVirtuals61800';

export const colorTypes61800: ColorTypes = {
  ...colorTypes,
  text2: '#73747d',
  text3: '#333',
  text4: '#000',
  text5: '#001c35',
  primary: '#ef4823',
  inform: '#ef4823',
  error: '#f30100',
  accent: '#fde500',
  background1: '#6d6d6d',
  background2: '#e2e2e2',
  background4: '#bbb',
  background5: '#989797',
  background6: '#ccc',
  background7: '#313131',
  neutral1: '#e7e7e7',
  neutral2: '#c1cad0',
  neutral3: '#FCD116',
  neutral4: '#7a7a7a',
};

export const colorCombinations61800 = getColorCombinations(colorTypes61800);

export const JSSThemeVariables61800: JSSThemeVariablesShapeType = {
  colors: {
    colorCombinations: colorCombinations61800,
    colorTypes: colorTypes61800,
  },
};

export const JSSTheme61800: JSSThemeShapeType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: {
        accent,
        white,
        background2,
        black,
        background5,
        text4,
        primary,
      },
    },
  } = themeVars;
  return {
    banners: JSSThemeBannerItems61800(themeVars),
    header: JSSThemeHeader61800(themeVars),
    headerMenu: JSSThemeHeaderMenu61800(themeVars),
    betslip: JSSThemeBetslip61800(themeVars),
    sportsMenu: JSSThemeSportsMenu61800(themeVars),
    layout: JSSThemeLayout61800(themeVars),
    languageSwitcher: JSSThemeLanguageSwitcher61800(themeVars),
    search: JSSThemeSearch61800(themeVars),
    coupons: JSSThemeCoupons61800(themeVars),
    widgets: JSSThemeDefaultWidgets61800(themeVars),
    form: JSSThemeForm61800(themeVars),
    authForm: JSSThemeAuthForm61800(themeVars),
    registration: JSSThemeRegistration61800(themeVars),
    balance: JSSThemeBalance61800(themeVars),
    casino: JSSThemeCasino61800(themeVars),
    mybets: JSSThemeMyBets61800(themeVars),
    accountSection: JSSThemeAccountSection61800(themeVars),
    live: JSSThemeLive61800(themeVars),
    cookies: JSSThemeCookies61800(themeVars),
    popularLeagues: JSSThemePopularLeagues61800(themeVars),
    article: JSSThemeArticleBody61800(themeVars),
    xtremepush: JSSThemeXtremepush61800(themeVars),
    virtuals: JSSThemeVirtuals61800(themeVars),
    Heading: {
      common: {
        color: black,
        padding: [margins.xs, 0, 0],
        fontSize: 30,
        '@media (pointer: coarse)': {
          color: white,
          padding: [margins.xs, margins.xm, 0],
        },
        '&.headingMobile': {
          color: black,
          // padding: [margins.xs, margins.xm, 0],
        },
        none: {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    NoEventsMessage: {
      root: {
        color: `${text4} !important`,
      },
    },
    DropDownUI: {
      title: {
        '&:hover': {
          opacity: 1,
        },
      },
    },
    PageNotFound: {
      pageNotFoundRoot: {
        background: background2,
      },
      title: {
        color: black,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.xxxl,
        },
      },
      desciption: {
        color: black,
        '@media (pointer: coarse)': {
          fontSize: fontSizes.md,
        },
      },
    },
    Button: {
      small: {
        padding: '5px 10px',
      },
      block: {
        '@media (pointer: coarse)': {
          fontWeight: 'bold',
        },
      },
      large: {
        padding: [margins.md, margins.xl],
      },
      linkDefault: {
        color: black,
        '@media not all and (pointer: coarse)': {
          //applyOnlyDesktop
          '&:hover': {
            color: background5,
          },
        },
        '@media (pointer: coarse)': {
          color: white,
        },
      },
      primary: {
        color: black,
        background: accent,
        '&:hover': {
          color: black,
          background: '#e9df7c',
        },
        '&:disabled': {
          '&:hover, &:active': {
            background: accent,
            color: black,
          },
        },
      },
      secondary: {
        color: black,
        background:
          'linear-gradient(90deg, rgba(239,72,35,1) 19%, rgba(253,229,0,1) 100%)',
        transition: '0.4s ease',
        transitionProperty: 'background-color',
        '&:hover': {
          color: black,
          background:
            'linear-gradient(90deg, rgba(239,72,35,1) 70%, rgba(253,229,0,1) 100%)',
        },
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.6,
          '&:hover, &:active': {
            background:
              'linear-gradient(90deg, rgba(239,72,35,1) 19%, rgba(253,229,0,1) 100%)',
            color: black,
          },
        },
      },
      accent: {
        color: black,
        background: primary,
        '&:hover': {
          color: black,
          background: '#f16a4c',
        },
      },
    },
    Geoblock: {
      rootGeoblock: {
        background: white,
      },
      logoGeoblock: {
        background: black,
      },
      textGeoblock: {
        color: black,
      },
    },
    bonuses: JSSThemeBonuses61800(themeVars),
    tooltip: JSSThemeTooltip61800(themeVars),
    footer: JSSThemeFooter61800(themeVars),
    footerAll: JSSThemeFooterAll61800(themeVars),
    headerMobile: JSSThemeHeaderMobile61800(themeVars),
    outcomes: JSSThemeOutcomes61800(themeVars),
    promotions: JSSThemePromotions61800(themeVars),
    sitemap: JSSThemeSitemap61800(themeVars),
    affiliates: JSSThemeAffiliates61800(themeVars),
    dropdownMenu: JSSThemeDropdownMenu61800(themeVars),
    LiveNow: {
      title: {
        background: `transparent !important`,
        borderRadius: '0px !important',
        width: '100% ',
        margin: '0 !important',
        padding: '0 !important',
        color: `${black} !important`,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        color: text4,
        background: white,
      },
    },
    OfflineMessage: {
      popup: {
        color: black,
      },
    },
    BetslipMyBetsPanelUI: {
      singleSelectionWrapper: {
        marginBottom: '5px',
        padding: 0,
      },
      panel: {
        background: 'white',
        padding: 10,
        margin: '10px 0 0',
        borderRadius: 0,
      },
    },
    NoBetsMessage: {
      root: {
        background: white,
        fontSize: fontSizes.sm,
      },
    },
    BetslipMybetsToggler: {
      root: { background: `${primary} !important` },
      badge: { color: `${primary} !important` },
      close: { background: `${primary} !important` },
    },
    LiveCalendar: {
      common: {
        margin: 0,
      },
      dateEvent: {
        color: black,
        background: white,
        '&:hover': {
          color: black,
          background: background2,
        },
      },
      link: {
        color: black,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        borderTop: `1px solid ${accent}`,
        paddingBottom: 3,
      },
    },
  };
};

export const getJSSThemePatterns61800: GetJSSThemePatternsType =
  getJSSThemePatterns;
