// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61800,
  JSSThemeVariables61800,
  getJSSThemePatterns61800,
} from './JSSTheme61800';
import { GeoblockPage61800 } from './GeoblockPage61800';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop61800')).FullDesktop61800,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile61800')).FullMobile61800,
  }),
  JSSTheme: JSSTheme61800,
  JSSThemeVariables: JSSThemeVariables61800,
  JSSThemePatterns: getJSSThemePatterns61800,
  GeoblockPage: GeoblockPage61800,
});
