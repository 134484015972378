// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61800: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { white, primary, inform, black },
    },
  } = themeVars;
  return {
    LoginFormDesktop: {
      popUpWrapper: {
        background: black,
        boxShadow: '0 5px 20px rgba(0, 0, 0, 0.8)',
        '&:before': {
          background: black,
        },
      },
    },
    LoginPage: {
      wrapper: {
        color: black,
      },
      wrapperMobile: {
        color: black,
      },
    },
    DepositButton: {
      link: {
        color: white,
        '&:hover': {
          color: white,
          opacity: 1,
        },
      },
    },
    AuthFormDesktop: {
      depositWrap: {
        border: 'none',
        background: primary,
        padding: '8px 12px',
        borderRadius: '3px',
        marginRight: margins.md,
        transition: '.3s ease-in',
        '&:hover': {
          background: inform,
          border: `none`,
          color: white,
        },
      },
    },
    PasswordResetWithEmail: {
      contactUsLink: {
        color: white,
        '&:hover': {
          color: white,
        },
      },
      passwordResetDebounce: {
        color: 'inherit',
        '@media (pointer: coarse)': {
          color: 'inherit',
        },
      },
    },
  };
};
