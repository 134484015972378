// @flow
import { type JSSThemeDefaultVirtualsType } from '../JSSThemeDefaultVirtuals';

export const JSSThemeVirtuals61800: JSSThemeDefaultVirtualsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { black, primary },
    },
  } = themeVars;
  return {
    Virtuals: {
      tab: {
        fontWeight: 'bold',
        color: black,
      },
      activeTab: {
        color: primary,
        boxShadow: `none`,
      },
    },
  };
};
