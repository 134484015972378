// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu61800: JSSThemeDefaultDropdownMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { neutral1, text3, primary },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      item: {
        background: neutral1,
        color: text3,
        '&:hover,&.active': { color:'#fff', background: primary },
      },
    },
    dropdownMobile: {
      selectorWrapMobileFlags: {
        display: 'flex',
      },
      selectorWrapMobile: {
        display: 'none',
      },
    },
  };
};
