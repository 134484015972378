// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates61800: JSSThemeDefaultAffiliatesType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { text3, text4, neutral3, accent, white },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      background: text3,
      margin: '0 auto',
      padding: '100px 20px 50px',
    },
    affiliatesHome: {
      topBanner: {
        background: text3,
      },
      topBannerHeader: {
        fontSize: '52px',
        lineHeight: '60px',
        '@media (pointer: coarse)': {
          fontSize: '28px',
          lineHeight: '38px',
        },
      },
      stepNumber: {
        flexShrink: 0,
      },
      topBannerDescription: {
        maxWidth: '500px',
      },
      affiliateRoot: {
        background: text3,
        minHeight: '100vh',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      productsRoot: {
        background: text3,
      },
      productsHeading: {
        fontWeight: '800 !important',
      },
      topBannerButton: {
        '&:hover': {
          color: '#fff',
        },
      },
      productsButton: {
        '&:hover': {
          color: '#fff',
        },
      },
      topBannerRoot: {
        background: text3,
      },
    },
    affiliatesProducts: {
      productsButton: {
        '&:hover': {
          color: '#fff',
        },
      },
    },
    affiliatesContacts: {
      contactsMail: {
        '&:hover': {
          color: neutral3,
        },
      },
    },
    affiliateHeader: {
      headerRoot: {
        background: text3,
      },
      burgerOverlay: {
        background: text3,
      },
      topMenuItem: {
        color: '#fff !important',
        '&:hover': {
          color: `${neutral3} !important`,
        },
      },
      topMenuItemActive: {
        color: `${neutral3} !important`,
      },
      loginBarRoot: {
        background: text4,
        '@media (pointer: coarse)': {
          background: text3,
        },
      },
      SignupRoot: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
          opacity: '.6',
        },
      },
      reasonsIcon: {
        fill: accent,
        width: '35px',
        height: '35px',
        flexShrink: 0,
      },
    },
    affiliatesFooter: {
      productsTerms: {
        color: '#fff',
        '&:hover': {
          color: neutral3,
        },
      },
      footer: {
        background: text3,
        opacity: 1,
      },
      footerRoot: {
        background: text3,
        marginTop: 'auto',
      },
      iconSocial: {
        fill: white,
        width: '22px',
        height: '22px',
        marginLeft: '18px',
        '&:hover': {
          fill: accent,
        },
        '@media (pointer: coarse)': {
          width: '42px',
          height: '42px',
          paddingRight: '5px',
          '&:first-child': {
            marginLeft: '0px',
          },
        },
      },
      socialWrap: {
        '@media (pointer: coarse)': {
          paddingBottom: '20px',
        },
      },
    },
    affiliatesFAQ: {
      FAQTextSpan: {
        textDecoration: 'underline',
      },
    },
  };
};
