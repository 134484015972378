// @flow
import { type JSSThemeDefaultWidgetsType } from '../JSSThemeDefaultWidgets';

export const JSSThemeDefaultWidgets61800: JSSThemeDefaultWidgetsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { accent, primary },
    },
  } = themeVars;
  return {
    LiveNowNotRankedEvent: {
      eventRoot: {
        border: `none`,
      },
      eventHeader: {
        boxShadow: 'none !important',
      },
      totalScore: {
        color: `${primary} !important`,
        fontWeight: 'bold',
      },
    },
    ClockTime: {
      clockTimePrematch: {
        color: `${accent} !important`,
      },
      clockTimeLive: {
        color: 'inherit !important',
      },
    },
  };
};
