// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile61800: JSSThemeDefaultHeaderMobileType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { primary, white, black },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      languageSelectWrap: {
        '&:nth-child(2)': {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'flex-end',
          paddingBottom: 5,
        },
      },
      menuRight: {
        color: white,
        background: black,
      },
      headerContainer: {
        background: black,
        color: primary,
      },
      menuContainer: {
        color: white,
        background: black,
      },
      iconContainer: {
        color: white,
      },
    },
  };
};
