// @flow
import { type JSSThemeDefaultLanguageSwitcherType } from '../JSSThemeDefaultLanguageSwitcher';

export const JSSThemeLanguageSwitcher61800: JSSThemeDefaultLanguageSwitcherType =
  (themeVars) => {
    const {
      colors: {
        colorTypes: { inform },
      },
    } = themeVars;
    return {
      LanguageSelectComponentMobile: {
        select: {
          color: inform,
        },
      },
    };
  };
