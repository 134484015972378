// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues61800: JSSThemeDefaultPopularLeaguesType = (
  themeVars
) => {
  const {
    fontSizes,
    colors: {
      colorTypes: { black, white },
    },
  } = themeVars;
  return {
    PopularLeagues: {
      header: {
        fontSize: fontSizes.xxl,
        color: white,
        '@media (pointer: coarse)': {
          fontWeight: 'normal !important',
        },
      },
      link: {
        color: 'white !important',
      },
      img: {
        // filter: 'invert(1)',
      },
      root: {
        background: black,
        '@media (pointer: coarse)': {
          background: black,
        },
      },
    },
  };
};
