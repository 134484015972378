// @flow
import { type JSSThemeDefaultTooltipType } from '../JSSThemeDefaultTooltip';

export const JSSThemeTooltip61800: JSSThemeDefaultTooltipType = (themeVars) => {
  return {
    Tooltip: {
      mobile: {
        maxWidth: 140,
        left: 'calc(100% - 25px)',
        '&:before': {
          right: 'calc(100% - 25px)',
          opacity: 0,
        },
      },
    },
  };
};
