// @flow
import { type JSSThemeDefaultBetslipType } from '../JSSThemeDefaultBetslip';

export const JSSThemeBetslip61800: JSSThemeDefaultBetslipType = (themeVars) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { primary, inform, white, background2, text4, black, accent },
    },
  } = themeVars;
  return {
    Betslip: {
      root: {
        background: white,
      },
      wrapTotals: {
        background: white,
      },
      info: {
        margin: 0,
        padding: [0, margins.md, margins.xm],
      },
      message: {
        background: '#fcd116',
        color: black,
      },
      readMoreLink: {
        color: black,
      },
    },
    BetslipTotals: {
      summaryLabel: {
        color: text4,
        paddingTop: margins.md,
        fontWeight: 'bold',
      },
      summaryValue: {
        color: text4,
      },
    },
    BetslipSelection: {
      common: {
        background: background2,
      },
    },
    MyBetsTab: {
      tab: {
        boxShadow: 'inset 0 5px 4px rgba(0, 0, 0, 0.06)',
        background: `#e7e7e7 !important`,
        '&.active, &:hover': {
          boxShadow: `none !important`,
          background: `${white} !important`,
        },
      },
    },
    Delta: {
      deltaIncreased: {
        background: `${inform} !important`,
        fontSize: fontSizes.sm,
      },
      deltaDecreased: {
        fontSize: fontSizes.sm,
        background: `${inform} !important`,
      },
    },
    BadgeAmount: {
      badgeAmount: {
        background: `${accent} !important`,
        color: black,
      },
    },
    ShareBets: {
      message: {
        '&.shown': {
          color: inform,
        },
      },
    },
    SingleSelectionStaticInfo: {
      priceDescription: {
        fontWeight: 'regular',
      },
      outcomePrice: {
        color: primary,
      },
    },
  };
};
