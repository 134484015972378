// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions61800: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { black, text2 },
    },
  } = themeVars;
  return {
    PromotionsComponent: {
      itemsByTypeTitle: {
        color: black,
      },
      promoLink: {
        color: text2,
        '&:hover, &:active': {
          color: text2,
          opacity: 0.7,
          borderBottom: `3px solid ${text2}`,
        },
      },
      listTabs: {
        '@media screen and (max-width: 500px)': {
          fontSize: '16px',
        },
        '@media screen and (max-width: 400px)': {
          gap: '0.5em',
          fontSize: '13px',
        },
      },
    },
    PromotionUI: {
      itemDescription: {
        '@media (pointer: coarse)': {
          padding: [margins.md, 0],
          color: black,
        },
      },
      itemBody: {
        minHeight: 380,
        backgroundPosition: '98% center',
        '@media screen and (max-width: 350px)': {
          minHeight: 340,
        },
      },
      itemSecondTitle: {
        color: black,
      },
    },
    PromotionsWrapper: {
      promotionsRoot: {
        padding: 0,
      },
      promotionsWrapper: {
        padding: 0,
        '@media (pointer: coarse)': {
          padding: [0, margins.xm],
        },
      },
    },
  };
};
