// @flow
import { type JSSThemeDefaultBalanceType } from '../JSSThemeDefaultBalance';

export const JSSThemeBalance61800: JSSThemeDefaultBalanceType = (themeVars) => {
  const {
    colors: {
      colorTypes: { primary, inform, accent, text4, white },
    },
  } = themeVars;
  return {
    UsernameCompanentMobile: {
      userNameMobile: {
        color: inform,
      },
    },
    UsernameCompanent: {
      userName: {
        color: text4,
      },
      accountNumber: {
        color: text4,
      },
    },
    Bonus: {
      bonusTitle: {
        color: text4,
      },
      bonusValue: {
        color: text4,
      },
      bonusValueMobile: {
        color: white,
      },
    },
    BalanceComponent: {
      titleContent: {
        color: 'black',
        border: 'none',
        padding: '8px 12px',
        borderRadius: '3px',
        background: accent,
        '&:hover': {
          background: '#e9dd6a',
          color: 'black',
          border: `none`,
        },
      },
      mobileLogoutWrap: {
        opacity: 1,
      },
      logoutWrap: {
        color: primary,
        fontWeight: 'bold',
        '&:hover': {
          color: inform,
          opacity: 1,
        },
      },
    },
    AccountPageMenu: {
      userMenuItem: {
        '&:hover': {
          color: inform,
        },
      },
    },
    AccountPageMenuDesktop: {
      userMenuItem: {
        color: '#000',
        '&:hover, &.active': {
          background: inform,
          color: '#fff',
        },
      },
    },
  };
};
